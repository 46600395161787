import { defineComponent, ref, onMounted } from 'vue';
export default defineComponent({
  setup: function setup() {
    var bannerList = ref([{
      url: require("../../assets/banner.jpg")
    } // {
    //     url: require("../../assets/banner2.jpg"),
    // },
    ]);
    var down1 = ref(false);
    var down2 = ref(false);
    var down3 = ref(false);
    var down4 = ref(false);

    var handleDown1 = function handleDown1() {
      down1.value = !down1.value;
    };

    var handleDown2 = function handleDown2() {
      down2.value = !down2.value;
    };

    var handleDown3 = function handleDown3() {
      down3.value = !down3.value;
    };

    var handleDown4 = function handleDown4() {
      down4.value = !down4.value;
    };

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView();
    };

    onMounted(function () {
      handlebackTop();
    });
    return {
      down1: down1,
      down2: down2,
      down3: down3,
      down4: down4,
      bannerList: bannerList,
      handleDown1: handleDown1,
      handleDown2: handleDown2,
      handleDown3: handleDown3,
      handleDown4: handleDown4,
      handlebackTop: handlebackTop
    };
  }
});